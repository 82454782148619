<template>
	<iframe
		src="https://wholesale.wookah.pl/api/public/api/tutorial"
		type="application/pdf"
		class="w-100 h-100 border-1s"
	>
		Oops! an error has occurred.
	</iframe>
</template>
<script>

export default {
	name: 'OtherHelp',
	components: {},
	computed: {},
	data: () => ({}),
	methods: {},
	created() {
	}
};
</script>
